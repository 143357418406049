import orderBy from 'lodash/orderBy';
import React, { useState, Dispatch, SetStateAction } from 'react';

import {
    CustomRowTableCellProps,
    DataTable,
    Header,
    OnSortArgs
} from '@amzn/imdb-shared-meridian-components/components/DataTable';
import { TableSortDirection } from '@amzn/meridian/table/table';

// TODO: remove this after implementing the API call
export type WorkflowStatus = {
    status: string;
    created: string;
    requestId: string;
    metadata: {
        context: string;
        feature: string;
        requestingUser: string;
        bucketName: string;
        successes: number;
        overrides: number;
        faults: number;
        queryExecutionId: string;
    };
};

export type ColumnId =
    | 'status'
    | 'created'
    | 'requestId'
    | 'context'
    | 'feature'
    | 'requestingUser'
    | 'bucketName'
    | 'successes'
    | 'overrides'
    | 'faults';

export enum JobStatus {
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
    RUNNING = 'RUNNING'
}

type Props = {
    statuses: WorkflowStatus[];
    setStatuses: Dispatch<SetStateAction<WorkflowStatus[]>>;
    isLoading: boolean;
};

export const translateToLodashSortDirection = (sortDirection: TableSortDirection) => {
    return sortDirection === 'ascending' ? 'asc' : 'desc';
};

export default function StatusTable({ statuses, setStatuses, isLoading }: Props) {
    const [sortColumn, setSortColumn] = useState<ColumnId>('created');
    const [sortDirection, setSortDirection] = useState<TableSortDirection>('ascending');

    const onSort = ({ sortColumn, sortDirection }: OnSortArgs<ColumnId>) => {
        setSortColumn(sortColumn);
        setSortDirection(sortDirection);
        const newStatuses = orderBy(statuses, [sortColumn], [translateToLodashSortDirection(sortDirection)]);
        setStatuses(newStatuses);
    };
    return (
        <DataTable
            data={{
                headers: createHeaders(),
                rows: statuses
            }}
            isLoading={isLoading}
            sort={{
                column: sortColumn,
                direction: sortDirection,
                onSort: onSort
            }}
            CustomRowTableCell={CustomRowTableCell}
        />
    );
}

const getStatusColor = (status: JobStatus) => {
    switch (status) {
        case JobStatus.SUCCEEDED:
            return 'green';
        case JobStatus.FAILED:
            return 'red';
        case JobStatus.RUNNING:
            return 'blue';
        default:
            return 'black';
    }
};
const StatusColorTableCellContent = ({ status }: { status: JobStatus }) => (
    <span style={{ color: `${getStatusColor(status)}` }}>{status}</span>
);
const DefaultTableCellContent = ({ content }) => <>{content}</>;

const CustomRowTableCell: React.FC<CustomRowTableCellProps<ColumnId>> = (props) => {
    const { row, headerId } = props;
    switch (headerId) {
        case 'status':
            return <StatusColorTableCellContent status={row[headerId as JobStatus]} />;
        default:
            return <DefaultTableCellContent content={row?.[headerId] ?? ''} />;
    }
};

const createHeaders = (): Header<ColumnId>[] => {
    return [
        {
            id: 'status',
            label: 'Status'
        },
        {
            id: 'created',
            label: 'Created'
        },
        {
            id: 'requestId',
            label: 'Request ID'
        },
        {
            id: 'context',
            label: 'Context'
        },
        {
            id: 'feature',
            label: 'Feature'
        },
        {
            id: 'requestingUser',
            label: 'Requested By'
        },
        {
            id: 'bucketName',
            label: 'Bucket Name'
        },
        {
            id: 'successes',
            label: 'Successes'
        },
        {
            id: 'overrides',
            label: 'Overrides'
        },
        {
            id: 'faults',
            label: 'Faults'
        }
    ];
};
