import { AxiosError } from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { RequestErrorCard } from '@amzn/imdb-shared-meridian-components/components/error/RequestErrorCard';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import { TableSortDirection } from '@amzn/meridian/table/table';

import { PERMISSION_REQUEST_LINK } from '../../../config/consts';
import StatusTable, { WorkflowStatus } from './components/StatusTable';

export const translateToLodashSortDirection = (sortDirection: TableSortDirection) => {
    return sortDirection === 'ascending' ? 'asc' : 'desc';
};

// TODO: remove this after implementing the API call and WorkflowStatus type in StatusTable
const TEST_DATA: WorkflowStatus[] = [
    {
        'requestId': 'test_a84afee4-c7ff-413d-a1c4-fc995ee4e491',
        'status': 'SUCCEEDED',
        'metadata': {
            'bucketName': 'imdb-loradmin-data-replication-input-test',
            'requestingUser': 'test1',
            'feature': 'replication',
            'successes': 1,
            'context': 'This is not a real context. Testing only.',
            'overrides': 0,
            'queryExecutionId': 'test_a84afee4-c7ff-413d-a1c4-fc995ee4e491',
            'faults': 0
        },
        'created': '2025-01-28T00:18:02.618Z'
    },
    {
        'requestId': 'test_a84afee4-c7ff-413d-a1c4-fc995ee4e500',
        'status': 'FAILED',
        'metadata': {
            'bucketName': 'imdb-loradmin-data-replication-input-test',
            'requestingUser': 'test3',
            'feature': 'replication',
            'successes': 0,
            'context': 'This is not a real context. Testing only.',
            'overrides': 1,
            'queryExecutionId': 'test_a84afee4-c7ff-413d-a1c4-fc995ee4e500',
            'faults': 1
        },
        'created': '2025-01-01T00:18:02.618Z'
    }
];

/*
 * Flattens the metadata object into the root object to make it easier to display/sort in the table
 */
export const flattenStatuses = (statuses: WorkflowStatus[]) => {
    return statuses.map((status) => {
        return {
            ...status,
            ...status.metadata
        };
    });
};

export default function BulkWorkflowStatus() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [statuses, setStatuses] = useState<WorkflowStatus[]>([]);
    const [error, setError] = useState<AxiosError | undefined>(undefined);

    const { state } = useLocation();

    useEffect(() => {
        async function fetchBulkShadowbanStatuses() {
            // TODO: Fetch statuses API here
            setStatuses(flattenStatuses(TEST_DATA));
            setError(undefined);
            setIsLoading(false);
        }
        fetchBulkShadowbanStatuses();
    }, []);

    if (error) {
        return <RequestErrorCard error={error} permissionsRequestLink={PERMISSION_REQUEST_LINK} />;
    }

    return (
        <div>
            {state && state.successMessage && <Alert type='success'>{state.successMessage}</Alert>}
            <Button onClick={() => navigate('/bulkWorkflow/create')}>Create Bulk Workflow</Button>
            <StatusTable statuses={statuses} setStatuses={setStatuses} isLoading={isLoading} />
        </div>
    );
}
