import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { getAccountId } from '../../../config/AppSettings';
import { WorkflowType, CreateWorkflowRequest, CreateWorkflowResponse } from '../../../listoramaAdmin-api/generated-src';
import ListORamaAdminApiFactory from '../../../listoramaAdmin-api/ListORamaAdminApiFactory';
import { validateCustomerId } from '../../../utils/validation';
import { BulkWorkflowFormView } from './BulkWorkflowFormView';

export interface BulkUpdateUserStatusFormInput {
    inputFile?: File;
    workflowType?: WorkflowType;
    workflowContext?: string;
}

interface BulkUpdateUserStatusResponseData {
    fetching?: boolean;
    error?: AxiosError;
}

export const buildWorkflowRequestInput = (inputData: BulkUpdateUserStatusFormInput): CreateWorkflowRequest => {
    const userAlias = window.sessionStorage.getItem('userAlias');
    if (!userAlias) {
        throw new Error('User alias not found! Please refresh page') as AxiosError;
    }
    if (!inputData.workflowType || !WorkflowType[inputData.workflowType]) {
        throw new Error('Please ensure you have selected a workflow') as AxiosError;
    }
    return {
        workflowType: WorkflowType[inputData.workflowType],
        operator: userAlias,
        workflowContext: inputData.workflowContext!
    };
};

export async function getWorkflowInfo(inputData: BulkUpdateUserStatusFormInput): Promise<CreateWorkflowResponse> {
    const workflowInput = buildWorkflowRequestInput(inputData);
    return ListORamaAdminApiFactory()
        .createWorkflow.createWorkflow(workflowInput)
        .then((response) => response.data);
}

interface UploadFileToS3Props {
    url: string;
    file: File;
    workflowcontext: string;
    workflowtype: string;
    workflowid: string;
}

const METADATA_HEADER_PREFIX = 'x-amz-meta-';
const REQUEST_HEADERS = {
    EXPECTED_BUCKET_OWNER: 'x-amz-expected-bucket-owner',
    OPERATOR: METADATA_HEADER_PREFIX + 'operator',
    WORKFLOW_TYPE: METADATA_HEADER_PREFIX + 'workflowType',
    WORKFLOW_CONTEXT: METADATA_HEADER_PREFIX + 'workflowContext',
    WORKFLOW_ID: METADATA_HEADER_PREFIX + 'workflowId'
};

export async function uploadFileToS3({ file, url, workflowcontext, workflowid, workflowtype }: UploadFileToS3Props) {
    const accountId = getAccountId(); // Add accountId to /localSettings/settings.json if want to use dev account

    /**
     * Metadata headers are used to store information about the workflow.
     * Metadata header values must match with the values used when created presigned URL.
     */
    const headers = new Headers({
        'Content-Type': 'text/plain',
        [REQUEST_HEADERS.EXPECTED_BUCKET_OWNER]: accountId,
        [REQUEST_HEADERS.OPERATOR]: window.sessionStorage.getItem('userAlias') || '',
        [REQUEST_HEADERS.WORKFLOW_TYPE]: workflowtype,
        [REQUEST_HEADERS.WORKFLOW_CONTEXT]: workflowcontext,
        [REQUEST_HEADERS.WORKFLOW_ID]: workflowid
    });
    const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: file
    });
    if (!response.ok) {
        throw new Error('Error uploading file to S3');
    }
}

export const BulkWorkflowForm: React.FC = () => {
    const [responseData, setResponseData] = useState<BulkUpdateUserStatusResponseData>({ fetching: false });
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    /**
     * Confirms the input file and workflow types are validly formed.
     */
    const validateInput = (inputData: BulkUpdateUserStatusFormInput) => {
        if (!inputData?.inputFile || inputData.inputFile.size === 0) {
            setResponseData({ error: new Error('Please ensure you have entered all inputs.') as AxiosError });
            return;
        }

        if (!inputData?.workflowType) {
            setResponseData({ error: new Error('Please ensure you have selected a workflow') as AxiosError });
            return;
        }

        inputData.inputFile.text().then((fileText) => {
            setIsModalOpen(true);
            for (const line of fileText.split(/[\r\n]+/)) {
                if (!validateCustomerId(line)) {
                    setResponseData({
                        error: new Error('Please validate input contains properly formatted customerIds') as AxiosError
                    });
                    return;
                }
            }
            setResponseData({ error: undefined });
            setIsModalOpen(true);
        });
    };

    const onInputChange = () => {
        // Clear any errors shown on page from invalid input
        setResponseData({ error: undefined });
    };

    /**
     * TODO: In this section, we still need to update request status in dynamodb after uploading the file.
     *
     * Currently, this function will only upload files to the location created by admin workflow.
     */
    const uploadInput = async (inputData: BulkUpdateUserStatusFormInput) => {
        if (!inputData?.inputFile) {
            setIsModalOpen(false);
            return;
        }

        setIsModalOpen(false);
        let workflowInfo = {} as CreateWorkflowResponse;
        try {
            setResponseData({ fetching: true });
            workflowInfo = await getWorkflowInfo(inputData);
        } catch (error) {
            setResponseData({ error: new Error('Error creating S3 path!') as AxiosError });
            return;
        }
        setResponseData({ fetching: false });

        try {
            setResponseData({ fetching: true });

            if (!workflowInfo.uploadURL) {
                setResponseData({ error: new Error('No presigned URL created to upload!') as AxiosError });
                return;
            }

            // Input validation already checked above.
            await uploadFileToS3({
                url: workflowInfo.uploadURL,
                file: inputData.inputFile,
                workflowcontext: inputData.workflowContext!,
                workflowid: workflowInfo.workflowId!,
                workflowtype: WorkflowType[inputData.workflowType!]
            });
        } catch (error) {
            setResponseData({ error: new Error('Error uploading file to S3') as AxiosError });
            return;
        }
        setResponseData({ fetching: false });

        navigate('/bulkWorkflow', {
            state: {
                successMessage: 'Bulk workflow submitted successfully! This workflow will be processed asynchronously.'
            }
        });

        return;
    };

    return (
        <BulkWorkflowFormView
            onInputChange={onInputChange}
            responseData={responseData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            uploadInput={uploadInput}
            validateInput={validateInput}
        />
    );
};
