import { AxiosError } from 'axios';
import React, { useState } from 'react';

import { AlertMessage } from '@amzn/imdb-shared-meridian-components/components/InputForm/InputForm';
import { SelectInput } from '@amzn/imdb-shared-meridian-components/components/InputForm/SelectInput/SelectInput';
import { TextareaInput } from '@amzn/imdb-shared-meridian-components/components/InputForm/TextareaInput/TextareaInput';
import { InputModal } from '@amzn/imdb-shared-meridian-components/components/InputModal';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import FileInput, { FileDetails } from '@amzn/meridian/file-input';
import Input from '@amzn/meridian/input';
import Loader from '@amzn/meridian/loader';
import { ModalProps } from '@amzn/meridian/modal/modal';
import RadioButton from '@amzn/meridian/radio-button';
import { SelectOptionProps } from '@amzn/meridian/select/select-option';
import Text from '@amzn/meridian/text';

import { WorkflowType } from '../../../listoramaAdmin-api/generated-src';

interface BulkWorkflowFormViewInput {
    inputFile?: File;
    workflowType?: WorkflowType;
    workflowContext?: string;
}

interface BulkWorkflowResponseData {
    skippedInputLines?: string[];
    fetching?: boolean;
    error?: AxiosError;
}

interface BulkWorkflowFormViewProps {
    responseData: BulkWorkflowResponseData;
    onInputChange: () => void;
    isModalOpen: boolean;
    setIsModalOpen: (_: boolean) => void;
    validateInput: (_: BulkWorkflowFormViewInput) => void;
    uploadInput: (_: BulkWorkflowFormViewInput) => void;
}

export const BulkWorkflowFormView: React.FC<BulkWorkflowFormViewProps> = (props) => {
    const { onInputChange, responseData, isModalOpen, setIsModalOpen, uploadInput, validateInput } = props;

    const [inputData, setInputData] = useState<BulkWorkflowFormViewInput>({});
    const [inputStyle, setInputStyle] = useState('manual');
    const [percentage, setPercentage] = useState<number>(0);
    const [inputLines, setInputLines] = useState('');

    const getWorkflowSelectOptions = (): SelectOptionProps[] => {
        return Object.keys(WorkflowType).map((workflowType) => {
            return { key: WorkflowType[workflowType], value: workflowType, label: workflowType };
        });
    };

    const setWorkflowType = (workflowType: WorkflowType) => {
        setInputData({ ...inputData, ...{ workflowType: workflowType } });
        onInputChange();
    };

    const setWorkflowContext = (workflowContext: string) => {
        setInputData({ ...inputData, ...{ workflowContext: workflowContext } });
        onInputChange();
    };

    const showLoader = () => {
        return (
            <Column width='50%' alignmentHorizontal='center'>
                <Loader />
                <Text>Processing Bulk Workflow...</Text>
            </Column>
        );
    };

    const onClickCancel = () => {
        setInputData({ ...inputData, ...{ inputFile: undefined } });
        onInputChange();
        setPercentage(0);
    };

    const onClickRemoveFile = () => {
        setInputData({ ...inputData, ...{ inputFile: undefined } });
        onInputChange();
        setPercentage(0);
    };

    const showInputLines = (): string => inputLines;

    const onFileInput = (acceptedFiles: File[]) => {
        const newFile = acceptedFiles[0];

        const newInputData = { ...inputData, ...{ inputFile: newFile } };
        setInputData(newInputData);
        onInputChange();
        setPercentage(100);
        return newInputData;
    };

    /**
     * Bundles the manual input into a file, and validates the file.
     */
    const onManualInputSubmit = () => {
        const blob = new Blob([inputLines], { type: 'text/plain' });
        const file = new File([blob], 'lorAdminTestFile.txt', { type: 'text/plain' });

        validateInput(onFileInput([file]));
    };

    const onFileInputSubmit = () => {
        validateInput(inputData);
    };

    const onModalSubmit = () => {
        uploadInput(inputData);
    };

    const showBulkWorkflowForm = () => {
        const alertMessage = getAlertMessage(responseData);

        return (
            <Column width='50%'>
                <InputModal
                    setIsOpen={setIsModalOpen}
                    modalProps={createModalProps(isModalOpen, inputData?.workflowType)}
                    onSubmit={onModalSubmit}
                />
                {alertMessage && (
                    <Alert type={alertMessage.type}>
                        {alertMessage.message.split('\n').map((m) => (
                            <div key={m}>{m}</div>
                        ))}
                    </Alert>
                )}
                <Text tag='label' type='b300' color='primary'>
                    Workflow
                </Text>
                <SelectInput
                    id={'workflowTypeDropdown'}
                    options={getWorkflowSelectOptions()}
                    value={inputData?.workflowType || ''}
                    onChange={setWorkflowType}
                    placeholder='Enter Worklow...'
                />
                <Text tag='label' type='b300' color='primary'>
                    Workflow Context
                </Text>
                <Text tag='label' type='b200' color='primary'>
                    Provide a SIM/tt link, or a simple explanation as to why the work is being done. Avoid including any
                    personal-identifying customer information (such as emails).
                </Text>
                <Input
                    id={'context'}
                    type='text'
                    value={inputData?.workflowContext || ''}
                    onChange={setWorkflowContext}
                    placeholder='Enter context...'
                />
                <Text tag='label' type='b300' color='primary'>
                    Input Type Select
                </Text>
                <RadioButton
                    checked={inputStyle === 'manual'}
                    onChange={setInputStyle}
                    name='inputStyle'
                    value='manual'
                    size='small'
                >
                    Manual Input
                </RadioButton>
                <RadioButton
                    checked={inputStyle === 'fileBased'}
                    onChange={setInputStyle}
                    name='inputStyle'
                    value='fileBased'
                    size='small'
                >
                    File Input
                </RadioButton>
                {inputStyle === 'manual' && (
                    <>
                        <TextareaInput
                            id='bulkWorkflowInput'
                            value={showInputLines()}
                            onChange={setInputLines}
                            rows={10}
                            helperText={'Enter Customer ID values one per each line.'}
                        />
                        <Button onClick={onManualInputSubmit}>Submit</Button>
                    </>
                )}

                {inputStyle === 'fileBased' && (
                    <FileInput type='single' onFileAttached={onFileInput} uploadButtonLabel={'Attach File'}>
                        {inputData.inputFile && (
                            <FileDetails
                                file={inputData.inputFile}
                                onClickCancel={onClickCancel}
                                onClickRemoveFile={onClickRemoveFile}
                                connectionSpeed='5MB/s'
                                cancelUploadButtonLabel={`Cancel, ${inputData.inputFile.name}`}
                                removeFileButtonLabel={`Delete, ${inputData.inputFile.name}`}
                                uploadPercentage={percentage}
                                uploadComplete={percentage === 100}
                            />
                        )}
                    </FileInput>
                )}
                {inputStyle === 'fileBased' && inputData.inputFile && (
                    <Button onClick={onFileInputSubmit}>Submit</Button>
                )}
            </Column>
        );
    };

    if (responseData.fetching) {
        return showLoader();
    } else {
        return showBulkWorkflowForm();
    }
};

const createModalProps = (isOpen: boolean, workflow?: WorkflowType): ModalProps => {
    return {
        title: 'Bulk Workflow',
        open: isOpen,
        children: [
            <Text key='description'>{`Are you sure you want to trigger a bulk workflow for these accounts${
                workflow ? ` for the ${workflow} Workflow` : ''
            }?`}</Text>
        ]
    };
};

const getAlertMessage = (responseData: BulkWorkflowResponseData): AlertMessage | undefined => {
    if (responseData.error) {
        return {
            type: 'error',
            message: responseData.error.message
        };
    }
    return undefined;
};
